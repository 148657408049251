<template>
    <div class="home full-size pos-abs tl-0" id="home">
        <div id="pano" ref="pano" class="pano-con pos-abs auto-ctmd">
            <noscript>
                <table style="width:100%;height:100%;">
                    <tr style="vertical-align:middle;text-align:center;">
                        <td>ERROR:<br><br>Javascript not activated<br><br></td>
                    </tr>
                </table>
            </noscript>
            
        </div>
        
        <transition name="fade">
            <div class=" pos-abs tl-0 full-size" v-show="!loadComp" :class="isios ? 'loading-con-hw-ios' : 'loading-con-hw'">
                <div class="loading-src-con">
                    <div class="loading-logo-con">
                        <img src="images/load_logo.png" alt="" class="loading-logo">
                    </div>
                    <div class="loading-bar-con">
                        <div class="loading-bar-bg2">
                            <div :class="isios ?  'warning-ios' :  'warning'" id="warning"
                                :style="'width:' + loadprog + '%'">
                            </div>
                        </div>
                    </div>
                    <div :class="isios ? 'loading-percentage-ios' :  'loading-percentage' ">{{loadprog}}%</div>
                    <div>Loading...</div>
                </div>
            </div>
        </transition>

        <!-- 背景音乐播放区 -->
        <!-- <audio id="bgm" loop src="sound/bg.mp3?v=202401040853"></audio> -->
        <audio id="bgm" loop src=""></audio>

        <!-- 中英切换预留按钮 -->
        <!-- <div class="btn-lang" @click="switchlang()">{{cueLang}}</div> -->

    </div>
</template>

<script>
    /* eslint-disable11 */
    // @ is an alias to /src

    export default {
        name: 'Home',
        components: {},
        data () {
            return {
                isHome: true,
                isimgpop: false,
                isTips: false, 
                krpano: null,
                cursceneinf: {},
                cueLang: '中文',
                settings: {},
                sceneinf: null,
                firstPl: false,
                bgm: null,
                recdInt: 0,
                loadComp: false,
                loadprog: 0,
                curMnId: 0,
                isPlay: false, //背景音乐控制 
                tmppause: null,
                showImgList: false,
                imgIndex: 0,
                imgList: [],
                videoUrl: '',
                infUrl: '',
                v_isplay: false,
                isiOS: null,
                curWidth: 0,
                isImgShow: true,
                num: null,
                videoItemShow: false, //首页2级视频
                videoItemUrl: '', 
                childrenIndex: null, 
                autoRot: false,  // 是否自动旋转
                isFullScreen: false, // 是否全屏 
            }
        },
        methods: { 
            goHome () {
                this.krpano.call(`gotoscene(scene_00_home)`)
                this.isHome = true
            }, 
            getCurSceneName (sendName) {
                sendName == 'scene_00_00_home' ? this.isHome = true : this.isHome = false
            },
            gotoSend (item, index) {
                this.childrenIndex = index
                if (item.sendId) {
                    this.krpano.call(`gotoscene(${item.sendId})`)
                    this.isHome = false;
                } else if (item.isimgpop) {
                    this.showImgList = true
                    // this.isimgpop = true; 
                    this.getLocSLang() == 'cn' ?
                        this.imgList = ['cn/images/资源 3.png', 'cn/images/资源 4.png'] :
                        this.imgList = ['en/images/资源 3.png', 'en/images/资源 4.png'];
                } else {
                    this.videoItemShow = true
                    // this.isimgpop = true; 
                    this.videoUrl = 'cn/video/SDS延时视频.mp4'
                }
                // this.krpano.call(`gotoscene(${e.sendId})`)
            },
            // 切换全屏
            fullscreen () {
                this.isFullScreen = !this.isFullScreen;
                console.log(this.isFullScreen)
                const element = document.getElementById('home'); // 替换 'yourElementId' 为要全屏显示的元素的 ID

                if (this.isFullScreen) {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.mozRequestFullScreen) { // Firefox
                        element.mozRequestFullScreen();
                    } else if (element.webkitRequestFullscreen) { // Chrome, Safari 和 Opera
                        element.webkitRequestFullscreen();
                    } else if (element.msRequestFullscreen) { // Internet Explorer/Edge
                        element.msRequestFullscreen();
                    }

                } else {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.mozCancelFullScreen) { // Firefox
                        document.mozCancelFullScreen();
                    } else if (document.webkitExitFullscreen) { // Chrome, Safari 和 Opera
                        document.webkitExitFullscreen();
                    } else if (document.msExitFullscreen) { // Internet Explorer/Edge
                        document.msExitFullscreen();
                    }
                }
            }, 
            Playornot () {
                this.autoRot = !this.autoRot;
                this.isAuto()
            },
            isAuto () {
                if (this.autoRot) this.krpano.call("start_auto_rotate()")
                else this.krpano.call("stop_auto_rotate()")
            },  
            // 切换中英文参数 切换中英文
            switchlang () {
                if (this.getLocSLang() == 'cn') {
                    this.setLocSLang('en');
                    this.getcurrentview();
                    window.localStorage.setItem("sceneinf", JSON.stringify(this.cursceneinf));
                    window.location.reload();
                }
                else {
                    this.setLocSLang('cn');
                    this.getcurrentview();
                    window.localStorage.setItem("sceneinf", JSON.stringify(this.cursceneinf));
                    window.location.reload();
                }
            },
            // 获取保存的语言值
            getLocSLang () {
                let value = localStorage.getItem("lang");
                if (value == null) {
                    window.localStorage.setItem("lang", "cn");
                    value = localStorage.getItem("lang");
                }
                //console.log('lang value:',value);
                return value;
            },
            // 设置语言值保存到本地存储
            setLocSLang (val) {
                window.localStorage.setItem("lang", val);
            },
            // 获取当前全景的场景位置信息
            getcurrentview () {
                if (this.krpano) {
                    //console.log('xml.scene:',krpano.get("xml.scene"));
                    this.cursceneinf.scene = this.krpano.get("xml.scene");
                    this.cursceneinf.hlookat = this.krpano.get("view.hlookat");
                    this.cursceneinf.vlookat = this.krpano.get("view.vlookat");
                    this.cursceneinf.fov = this.krpano.get("view.fov");
                    this.cursceneinf.distortion = this.krpano.get("view.distortion");
                    //console.log('cur scene inf:',JSON.stringify(this.cursceneinf));                  
                }
            },
            //判断平台系统。
            getSys () {
                let tagSys = false;
                let u = navigator.userAgent;
                let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
                let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

                let isPC = !u.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
                // console.log('是否是Android：'+isAndroid);
                // console.log('是否是iOS：'+isIOS); 
                // console.log('是否是PC：'+isPC); 
                if (isAndroid) tagSys = 'Android';
                if (isIOS) tagSys = 'IOS';
                if (isPC) tagSys = 'PC';
                //console.log('当前平台：' + tagSys);
                return tagSys;
            },

            isIOS4 () {
                let isiOS = false;
                if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    isiOS = true;
                }
                return isiOS;
            },
            setScale () {
                var html = document.getElementsByTagName('html')[0];
                //屏幕的宽度（兼容处理）
                var w = document.documentElement.clientWidth || document.body.clientWidth;
                //750这个数字是根据你的设计图的实际大小来的，所以值具体根据设计图的大小
                html.style.fontSize = w / 1920 + "px";
            },
            load_scripts (urls, callback) {
                let _this = this;
                if (urls.length > 0) {
                    var url = this.resolve_url_path(urls.splice(0, 1)[0]);

                    var script = document.createElement("script");
                    script.src = url;
                    script.addEventListener("load", function () {
                        _this.load_scripts(urls, callback);
                    });
                    script.addEventListener("error", function () {
                        console.log("loading file '" + url + "' failed!");
                    });
                    document.getElementsByTagName("head")[0].appendChild(script);
                } else {
                    // done
                    callback();
                }
            },

            resolve_url_path (url) {
                if (url.charAt(0) != "/" && url.indexOf("://") < 0) {
                    // adjust relative url path
                    url = "./" + url;
                }

                return url;
            },

            preloadImg () {
                // console.log('arguments:',arguments); 
                //let count = 0;
                for (let i = 0; i < arguments.length; i++) {
                    this.images[i] = new Image();
                    this.images[i].src = arguments[i];
                }
            },

            krpano_onready_callback (krpano_interface) {
                this.krpano = krpano_interface;
                //console.log('pano is ready. tag:',this.krpano);
                var prointval;
                prointval = setInterval(() => {
                    // console.log(this.krpano.get('progress.progress'));
                    this.loadprog = Math.round(this.krpano.get('progress.progress') * 100);
                    if (this.krpano.get('progress.progress') >= 0.86) {
                        clearInterval(prointval);
                        this.loadComp = true;
                        this.curMnId = this.krpano.get("scene[get(xml.scene)].index");
                    }
                }, 40);

            },

            initpano () {
                console.log('旋转屏幕,重新init')
                //localStorage.removeItem("lang"); 
                this.cueLang = this.getLocSLang() == 'cn' ? "EN" : "中文";
                //let curxml=this.getLocSLang() == 'cn'?"cn/tour.xml":"en/tour.xml";
                //let curxmlmb=this.getLocSLang() == 'cn'?"cn/tour.xml":"en/tour.xml";
                // let curxml,curxmlmb;
                // if(this.getLocSLang() == 'cn'){
                //     this.cueLang='英文';
                //     curxml="cn/tour.xml";
                //     curxmlmb="cn/tour.xml";
                // }
                // else{
                //     this.cueLang='中文';
                //     curxml="en/tour.xml";
                //     curxmlmb="en/tour.xml";
                // }
                //console.log('cur xml:',curxml);

                this.sceneinf = JSON.parse(localStorage.getItem("sceneinf"));
                //console.log('scene inf:',this.sceneinf);
                window.localStorage.removeItem("sceneinf");
                //console.log('ss scene inf:',JSON.parse(localStorage.getItem("sceneinf"))); 
                if (this.sceneinf) {
                    this.settings["startscene"] = this.sceneinf.scene;
                    let lookatParams = this.sceneinf.hlookat + ',' + this.sceneinf.vlookat + ',' + this.sceneinf.fov;
                    //console.log('lookatParams:',lookatParams);
                    this.settings["startactions"] = "lookat(" + lookatParams + ",0,0)";
                    //settings["view.hlookat"] = parseInt(sceneinf.hlookat); 
                    //settings["onstart"] = "trace('on start...');";
                }
                this.settings["lang"] = this.getLocSLang();
                //let langpath="%VIEWER%/"+this.getLocSLang();
                //console.log('lang path:',langpath);
                if (this.isPc()) {
                    window.embedpano({
                        swf: "tour.swf",
                        xml: "tour.xml?v="+Date.now(),
                        target: "pano",
                        html5: "auto",
                        mobilescale: 1.0,
                        passQueryParameters: true,
                        onready: this.krpano_onready_callback,
                        vars: this.settings,
                        initvars: { SOURCE: "%VIEWER%/" + this.getLocSLang() },
                        consolelog: true
                    });
                } else {
                    window.embedpano({
                        swf: "tour.swf",
                        xml: "tour.xml?v="+Date.now(),
                        target: "pano",
                        html5: "auto",
                        mobilescale: 1.0,
                        passQueryParameters: true,
                        onready: this.krpano_onready_callback,
                        vars: this.settings,
                        initvars: { SOURCE: "%VIEWER%/" + this.getLocSLang() },
                        consolelog: true
                    });
                }
            },
            open_imglist_win (num) {
                num = parseInt(num)
                console.log(num)
                switch (num) {
                    case 1: //sds视频
                        // getLocSLang 判断当前是中英指定不同参数地址。
                        this.getLocSLang() == 'cn' ?
                            this.imgList = ['cn/images/资源 3.png', 'cn/images/资源 4.png'] :
                            this.imgList = ['en/images/资源 3.png', 'en/images/资源 4.png'];
                        break;

                    default:
                        this.videoUrl = '';
                        break;
                }
                this.showImgList = true
                console.log(this.showImgList, this.imgList)
            },
            open_vid_win (id) {
                let num = parseInt(id);
                this.num = num
                switch (num) {
                    case 1: //sds视频
                        // getLocSLang 判断当前是中英指定不同参数地址。
                        this.getLocSLang() == 'cn' ?
                            this.videoUrl = 'cn/video/SDS延时视频.mp4' :
                            this.videoUrl = 'https://e-file.huawei.com/mediares/Video_MCD/EBG/PUBLIC/en/2022/12/07403e09-88be-4308-ae66-98f771058230.mp4';
                        break;

                    default:
                        this.videoUrl = '';
                        break;
                }
                this.videoItemShow = true;
                this.tmppause = this.isPlay;
                console.log('video win param:', this.videoUrl, this.num);
                // this.pauseSound();

            },

            open_img_win (id) {
                let num = parseInt(id);
                console.log(id)
                switch (num) {
                    case 1: //集控电站管理弹窗
                        this.getLocSLang() == 'cn' ?
                            this.imgSrc = 'cn/res/04_jikong_01/pop.png' :
                            this.imgSrc = 'en/res/04_jikong_01/pop.png';
                        break;
                    case 2: //集控IV弹窗
                        this.getLocSLang() == 'cn' ?
                            this.imgSrc = 'cn/res/04_jikong_01/pop.png' :
                            this.imgSrc = 'en/res/04_jikong_01/pop.png';
                        break;
                    // this.getLocSLang() == 'cn' ?
                    //     this.infUrl = 'en/res/00_02SwuIntroduction  b_img_mb' :
                    //     this.infUrl = 'en/res/00_02SwuIntroduction  b_img_mb';
                    // break;

                    default:
                        this.imgUrl = '';
                        break;
                }
                this.isImgShow = true;
                console.log('win param:', this.infUrl, num);
            },


            checkBgMusic () {
                if (this.tmppause) {
                    this.isPlay = true;
                    this.playState();
                    return;
                }
                if (this.krpano) {
                    this.krpano.call("voice_action();");
                }
            },
            tempPauseBgMusic () {
                this.tmppause = this.isPlay;
                this.pauseSound();
            },
            resumeSound () {
                if (!this.isPlay) { return }
                if (this.tmppause) {
                    this.bgm.pause();
                } else if (!this.tmppause) {
                    this.bgm.play();
                }

                if (this.krpano) {
                    this.krpano.call("toggle_music_icon();");
                }
            },
            switchSound () {
                this.isPlay = !this.isPlay;
                this.playState();
            },

            pauseSound () {
                this.isPlay = false;
                this.playState();
                if (this.krpano) {
                    this.krpano.call("voice_action();");
                }
            },

            readyplay () {
                if (this.firstPl) {
                    this.bgm.play();
                    this.firstPl = false;
                    this.isPlay = true
                    // console.log('isPlay:', this.isPlay); 
                    setTimeout(() => {
                        this.bgm.play();
                        this.bgm.oncanplaythrough = function () {
                            this.bgm.play();
                            console.log('oncanplaythrough.');
                        }
                    }, 2000);

                    if (this.krpano) {
                        this.krpano.call("toggle_music_icon();");
                    }
                }
            },

            playState () {
                if (this.isPlay) {
                    //this.bgm.currentTime = 0;
                    // this.bgm.currentTime = localStorage.getItem('bgm_time');
                    this.bgm.play();
                    if (typeof (Storage) !== 'undefined') {
                        localStorage.setItem('bgm_isplay', "true");
                        // console.log("bgm_isplay:"+ localStorage.getItem('bgm_isplay'));
                    } else {
                        let doc_body = document.querySelector('body');
                        doc_body.innerHTML = '<h1>抱歉！您的浏览器过旧，请更换新的浏览器再试</h1>';
                    }
                } else {
                    this.bgm.pause();
                    if (typeof (Storage) !== 'undefined') {
                        // localStorage.removeItem('bgm_isplay'); 
                        localStorage.setItem('bgm_isplay', "false");
                    } else {
                        let doc_body = document.querySelector('body');
                        doc_body.innerHTML = '<h1>抱歉！您的浏览器过旧，请更换新的浏览器再试</h1>';
                    }
                }

                if (this.krpano) {
                    this.krpano.call("toggle_music_icon();");
                }
            },

            //判断是手机端还是pc端
            isPc () {
                if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                    // if (window.orientation == 90 || window.orientation == -90) {
                    //     console.log('移动端-横屏')
                    //     return true; // 移动端

                    // } else {
                    //     console.log('移动端-竖屏')
                    //     // 横屏-隐藏提示
                    //     return false; // 移动端
                    // }
                    return false; // 移动端
                } else {
                    return true; // PC端
                }
            },

            startPlayforIOS () {
                if (localStorage.getItem('bgm_time') != null) {
                    this.bgm.currentTime = localStorage.getItem('bgm_time')
                    if (localStorage.getItem('bgm_isplay') == 'true') {
                        //this.bgm.currentTime = localStorage.getItem('bgm_time');
                        this.bgm.currentTime = 0;
                        this.readyplay()
                    } else if (localStorage.getItem('bgm_isplay') == null) {
                        this.readyplay()
                    }
                } else {
                    this.readyplay()
                }

                //不断循环记录播放进度
                this.recdInt = setInterval(() => {
                    if (typeof (Storage) !== 'undefined') {
                        //写入BGM播放进度
                        localStorage.setItem('bgm_time', this.bgm.currentTime);
                        //console.log("currentTime:"+this.bgm.currentTime);
                    } else {
                        //提示浏览器不支持
                        var doc_body = document.querySelector('body');
                        doc_body.innerHTML = '<h1>抱歉！您的浏览器过旧，请更换新的浏览器再试</h1>';
                    }
                }, 1010);
            },
        },
        created () {

            this.load_scripts(["tour.js"], this.initpano);
            this.isios = this.isIOS4()

        },
        mounted () {


            // window.addEventListener("orientationchange", this.initpano);
            // alert(this.getSys(), 637)
            window.vm = this;
            window.closewin_yugu = this.closewin_yugu
            this.curWidth = document.documentElement.clientWidth;
            this.setScale();

            window.addEventListener('resize', () => {
                this.setScale();
                this.curWidth = document.documentElement.clientWidth;
            });

            //背景音乐
            this.bgm = document.getElementById("bgm");

            this.bgm.volume = 0.8;

            if (typeof (Storage) !== 'undefined') {
                //写入BGM播放进度
                localStorage.setItem('bgm_time', 0);
                //console.log("currentTime:"+this.bgm.currentTime);
            }

            if (this.firstPl) {
                setTimeout(() => {
                    this.startPlayforIOS();
                    if (this.krpano) {
                        this.krpano.call("delayedcall(1,toggle_music_icon();)");
                    }
                }, 500);
            }

            let curSys = this.getSys();
            // console.log('curSys:',curSys); 
            if (curSys != 'IOS') {
                //音轨补偿代码
                setTimeout(() => {
                    // console.log('locStg bgm_time:',localStorage.getItem('bgm_time'));
                    console.log('bgm is play:', localStorage.getItem('bgm_isplay'));

                    // this.startPlayforIOS();
                    if (this.krpano) {
                        this.krpano.call("toggle_music_icon();");
                    }

                }, 3000);
            }
 
        }
    }
</script>
<style scoped>
    /*放在 mycss.css 里了。*/
</style>